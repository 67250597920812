<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-12"><h1 class="title-w2">My Account</h1></div>
    </div>
  </div>
  <div class="container account-container">
    <!-- Personal Details -->
    <div class="row divider-row">
      <div class="col-12">
        <h3>Personal Details</h3>
        <div class="section-divider"></div>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-12 col-md-6 col-lg-5">
        <div class="form-item">
          <label class="form-label">First name</label>
          <input
            type="text"
            class="form-control"
            placeholder="First Name"
            v-model="profile.firstName"
            required
          />
          <span class="form-error">{{ profileError.firstName }}</span>
        </div>
      </div>
      <div class="col-md-6 col-lg-5">
        <div class="form-item">
          <label class="form-label">Last name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Last Name"
            v-model="profile.lastName"
            required
          />
          <span class="form-error">{{ profileError.lastName }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-10">
        <div class="form-item">
          <label class="form-label">Username</label>
          <input
            type="text"
            class="form-control"
            placeholder="Username"
            v-model="profile.username"
            required
          />
          <span class="form-error">{{ profileError.username }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-10">
        <div class="form-item">
          <label class="form-label">Company</label>

          <input
            type="text"
            class="form-control"
            placeholder="Organisation"
            v-model="profile.company"
            required
          />
          <span class="form-error">{{ profileError.company }}</span>
        </div>
      </div>

      <div class="col-12 col-lg-10">
        <div class="form-item">
          <label class="form-label">Email</label>
          <input
            type="email"
            class="form-control"
            placeholder="Email Address"
            v-model="profile.email"
            required
          />
          <span class="form-error">{{ profileError.email }}</span>
        </div>
      </div>

      <div class="col-12 col-lg-10 btn-wrapper">
        <el-button @click="getProfile">Cancel</el-button>
        <el-button type="primary" @click="updateProfile">Save</el-button>
      </div>
    </div>
    <!-- Edit Password -->
    <div class="row divider-row">
      <div class="col-12">
        <h3>Edit password</h3>
        <div class="section-divider"></div>
      </div>
      <div class="text-end">
        <p class="link" @click="showModal = true">Forgot your password?</p>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-12 col-lg-10">
        <div class="form-item input-group">
          <label class="form-label">Current password</label>
          <input
            :type="show.old_password ? 'text' : 'password'"
            class="form-control"
            placeholder="Current password"
            v-model="currentPassword"
            required
          />
          <span class="input-group-text cursor-pointer"
            ><i
              :class="
                show.old_password ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
              "
              @click="show.old_password = !show.old_password"
            ></i
          ></span>
        </div>
      </div>

      <div class="col-12 col-lg-10">
        <div class="form-item input-group">
          <label class="form-label">New password</label>
          <input
            :type="show.new_password ? 'text' : 'password'"
            class="form-control"
            placeholder="New password"
            v-model="newPassword"
            required
          />
          <span class="input-group-text cursor-pointer"
            ><i
              :class="
                show.new_password ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
              "
              @click="show.new_password = !show.new_password"
            ></i
          ></span>
          <span class="form-error">{{ passwordError.newPassword }}</span>
        </div>
      </div>

      <div class="col-12 col-lg-10">
        <div class="form-item input-group">
          <label class="form-label">Confirm new password</label>
          <input
            :type="show.password_confirm ? 'text' : 'password'"
            class="form-control"
            placeholder="Confirm new password"
            v-model="passwordConfirm"
            required
          />
          <span class="input-group-text cursor-pointer"
            ><i
              :class="
                show.password_confirm
                  ? 'bi bi-eye-slash-fill'
                  : 'bi bi-eye-fill'
              "
              @click="show.password_confirm = !show.password_confirm"
            ></i
          ></span>
          <span class="form-error">{{ passwordError.passwordConfirm }}</span>
        </div>
      </div>
      <div class="col-12 col-lg-10 btn-wrapper">
        <el-button>Cancel</el-button>
        <el-button type="primary" @click="updatePassword">Save</el-button>
      </div>
    </div>
  </div>
  <el-dialog v-model="showModal" title="Forgot your password?">
    <div class="row modal-form-row">
      <div class="col-12 col-lg-8">
        <div class="form-item">
          <label class="form-label">Email address</label>
          <input
            type="email"
            class="form-control"
            placeholder="Please enter your email address"
            v-model="modalEmail"
            required
          />
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showModal = false">Cancel</el-button>
        <el-button type="primary" @click="forgotPassword">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Feedback from '@/utils/services/Feedback';
import {
  getProfileValidation,
  getPasswordValidation,
  getPasswordConfirmation,
} from '@/utils/helpers/getValidation';

export default {
  name: 'Account',
  data() {
    return {
      profile: {
        firstName: '',
        lastName: '',
        username: '',
        company: '',
        email: '',
      },
      profileError: {
        firstName: '',
        lastName: '',
        username: '',
        company: '',
        email: '',
      },
      currentPassword: '',
      newPassword: '',
      passwordConfirm: '',
      passwordError: {
        newPassword: '',
        passwordConfirm: '',
      },
      isValid: false,
      show: {
        old_password: false,
        new_password: false,
        confirm_password: false,
      },
      showModal: false,
      modalEmail: '',
    };
  },
  created() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      this.$store.dispatch('getProfile').then((userData) => {
        this.profile = {
          firstName: userData.first_name,
          lastName: userData.last_name,
          username: userData.username,
          company: userData.company_name,
          email: userData.email,
        };
      });
    },
    updateProfile() {
      this.isValid = false;
      const { isValid, formError } = getProfileValidation(this.profile);
      this.isValid = isValid;
      this.profileError = formError;

      if (!this.isValid) {
        return;
      }

      this.$store
        .dispatch('updateProfile', {
          first_name: this.profile.firstName,
          last_name: this.profile.lastName,
          username: this.profile.username,
          company_name: this.profile.company,
          email: this.profile.email,
        })
        .then((res) => {
          if (res)
            Feedback.showSuccess('Your profile has been updated successfully.');
          else Feedback.showError('There was an error updating your profile.');
        });
    },
    validatePasswords() {
      const passwordValidation = getPasswordValidation(this.newPassword);
      const passwordConfirmation = getPasswordConfirmation(
        this.newPassword,
        this.passwordConfirm
      );

      this.formError = {
        password: passwordValidation,
        passwordConfirm: passwordConfirmation,
      };

      if (passwordValidation === '' && passwordConfirmation === '') {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },
    updatePassword() {
      this.validatePasswords();
      if (!this.isValid) {
        return;
      }

      this.$store
        .dispatch('updatePassword', {
          current_password: this.currentPassword,
          new_nassword: this.newPassword,
        })
        .then((res) => {
          if (res)
            Feedback.showSuccess(
              'Your password has been updated successfully.'
            );
          else Feedback.showError('There was an error updating your password.');
        });
    },
    forgotPassword() {
      this.$store.dispatch('forgotPassword', this.modalEmail).then((res) => {
        if (res.status === 200)
          Feedback.showSuccess(
            'An email has been sent to reset your password.'
          );
        else
          Feedback.showError(
            'There was an error sending the email. Please try again.'
          );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  button:nth-child(1) {
    margin-right: 1rem;
  }
  .el-button {
    font-size: 16px !important;
  }
}

.row.g-3 {
  display: flex;
  justify-content: flex-end;
}

.account-container {
  width: 60%;
  padding-bottom: 3rem !important;
}

@include bp-down(lg) {
  .account-container {
    width: 80%;
  }
}

@include bp-down(md) {
  .account-container {
    width: 95%;
    .row.g-3 {
      justify-content: center;
    }
  }
}
</style>
